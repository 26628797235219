.navigation-menu {
  top: 50px;
  height: calc(100vh - 50px);
  transform: translateX(-100%);
  transition: 300ms ease transform;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.is-open {
  transform: translateX(0);
}
